var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/ford-logo.png"),"alt":"logo"}})]),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Dokończ zakładanie konta ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Utwórz hasło i potwierdź założenie konta, by mieć dostęp do szkoleń oraz materiałów szkoleniowych ")]),_c('validation-observer',{ref:"confirmUserForm"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Hasło","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Powtórz hasło"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password","custom-messages":{
                confirmed: 'Podane hasła różnią się.'
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm"},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } },"custom-messages":{
              required: 'Wymagane'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-agreement","name":"checkbox-agreement","state":errors.length > 0 ? false:null},model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_vm._v(" Informuję, że wyrażam zgodę na przetwarzanie moich danych osobowych w celach realizowania procesu szkoleniowego. "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('b-button',{attrs:{"block":"","type":"submit","variant":"primary"}},[_vm._v(" Potwierdź założenie konta ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":"/login"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Powrót do logowania ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }