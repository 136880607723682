<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/ford-logo.png"
            alt="logo"
          >
        </b-link>
        <b-card-title class="mb-1">
          Dokończ zakładanie konta
        </b-card-title>
        <b-card-text class="mb-2">
          Utwórz hasło i potwierdź założenie konta, by mieć dostęp do szkoleń oraz materiałów szkoleniowych
        </b-card-text>

        <!-- form -->
        <validation-observer ref="confirmUserForm">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="submit"
          >

            <!-- password -->
            <b-form-group
              label="Hasło"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Powtórz hasło"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
                :custom-messages="{
                  confirmed: 'Podane hasła różnią się.'
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider
              #default="{ errors }"
              :rules="{ required: { allowFalse: false } }"
              :custom-messages="{
                required: 'Wymagane'
              }"
            >
              <b-form-group>
                <b-form-checkbox
                  id="checkbox-agreement"
                  v-model="agreement"
                  name="checkbox-agreement"
                  :state="errors.length > 0 ? false:null"
                >
                  Informuję, że wyrażam zgodę na przetwarzanie moich danych osobowych w celach realizowania procesu szkoleniowego.
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Potwierdź założenie konta
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link to="/login">
            <feather-icon icon="ChevronLeftIcon" /> Powrót do logowania
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      agreement: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    submit() {
      this.$refs.confirmUserForm.validate().then(success => {
        if (success) {
          const payload = {
            token: this.$route.params.token,
            password: this.password,
          }
          this.$store.dispatch('user/confirmUser', { id: this.$route.params.id, payload }).then(response => {
            if (response && response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Konto potwierdzone',
                  text: 'Możesz się teraz zalogować.',
                  icon: 'UserCheckIcon',
                  variant: 'success',
                },
              })
              this.$router.replace('/login')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Nie udało się potwierdzić rejestracji',
                  text: 'Skontaktuj się z administratorem.',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
